<template>
    <a-row type="flex" justify="center" align="middle" style="background: rgb(27, 36, 48); height: 100vh;">
        <a-col :span="6">
            <a-card title="登录 - SCRM 管理后台">
                <a-alert type="error" :message="error" banner v-if="error"/>
                <a-form :model="loginForm" @submit="login">
                    <a-form-item>
                        <a-input v-model:value="loginForm.username" placeholder="用户名">
                            <template v-slot:prefix>
                                <user-outlined type="user" />
                            </template>
                        </a-input>
                    </a-form-item>
                    <a-form-item>
                        <a-input v-model:value="loginForm.password" type="password" placeholder="密码">
                            <template v-slot:prefix>
                                <lock-outlined type="lock" />
                            </template>
                        </a-input>
                    </a-form-item>
                    <a-form-item>
                        <a-button type="primary" html-type="submit"
                                  :disabled="loginForm.username === '' || loginForm.password === ''">
                            登录
                        </a-button>
                    </a-form-item>
                </a-form>
            </a-card>
        </a-col>
    </a-row>
</template>

<script>
import {UserOutlined, LockOutlined} from '@ant-design/icons-vue';
import loginApi from "@/service/api/login";
import {apiStore} from "@/shared/service/api-client";

export default {
    name: 'Login',
    components: {
        UserOutlined,
        LockOutlined,
    },
    data () {
        return {
            error: "",
            loginForm: {
                username: '',
                password: '',
            }
        }
    },
    methods: {
        login: async function() {
            try {
                const logined = await loginApi.login(this.loginForm);
                apiStore.setAuth(logined.token, logined.user);
            } catch (err) {
                this.error = err.message;
                return ;
            }
            await this.$router.push({name: "background_page"});
        }
    }
}
</script>
